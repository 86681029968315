import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ae15540"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "acTypesAuth_unlink"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["acTypesAuth", {
      '-line': _ctx.shortBtn
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (client) => {
      return (_openBlock(), _createElementBlock("a", {
        key: client.name,
        class: _normalizeClass(["-blocked acTypesAuth_btn", _ctx.btnClasses(client)]),
        onClick: ($event: any) => (_ctx.selectTypeAuth(client))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["acTypesAuth_icon", `-${client.name}`])
        }, null, 2),
        (!_ctx.shortBtn)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.getTextBtn(client.name)), 1)
            ], 64))
          : _createCommentVNode("", true),
        (client.linked)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}