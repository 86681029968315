
import { defineComponent, computed } from 'vue'

import SettingsContent from '@web/components.v2/Settings/Content.vue'
import TypesAuth from '@web/components.v2/Settings/SocialNetworks/TypesAuth.vue'
import AcButton from '@ui-kit/components/AcButton.vue'

import i18n from '@web/plugins/i18n'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'SocialNetworks',
  components: {
    AcButton,
    SettingsContent,
    TypesAuth
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const route = computed(() => useRoute())
    const router = useRouter()
    const isAfterRegistration = computed(() => route.value.name === 'linkSocialNetworks')
    const btnText = computed(() => isAfterRegistration.value ? i18n.global.t('Skip') : i18n.global.t('Back'))

    function buttonHandler () {
      if (route.value.meta.skip) {
        router.push({ name: 'courses' })
      } else {
        router.go(-1)
      }
    }

    return {
      isAfterRegistration,
      btnText,
      buttonHandler
    }
  }
})
