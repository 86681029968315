import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-156c45f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "acSocialNetworks" }
const _hoisted_2 = { class: "acSocialNetworks_wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "acSocialNetworks_title"
}
const _hoisted_4 = { class: "acSocialNetworks_description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_types_auth = _resolveComponent("types-auth")!
  const _component_ac_button = _resolveComponent("ac-button")!
  const _component_settings_content = _resolveComponent("settings-content")!

  return (_openBlock(), _createBlock(_component_settings_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('linkSocialNetworks')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('linkSocialNetworksDescription')) + " " + _toDisplayString(_ctx.$t('selectFromList')) + ": ", 1),
          _createVNode(_component_types_auth, {
            class: "acSocialNetworks_social",
            action: "link"
          }),
          (_ctx.isAfterRegistration)
            ? (_openBlock(), _createBlock(_component_ac_button, {
                key: 1,
                variant: "text",
                class: "acSocialNetworks_btn",
                onClick: _ctx.buttonHandler
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.btnText), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}